import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import OfficeInfoCard from '../middle/OfficeInfoCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TownCardsBox: {
      padding: `1rem 0`,
      marginTop: `-2rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 15%, ${theme.palette.background.default} 45%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const OfficeInfoCardsBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.TownCardsBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12} md={6}>
          <OfficeInfoCard
            cardTitle="事務所情報"
            contentsList={[
              `事務所名：鈴木弘基税理士事務所`,
              `住所：埼玉県富士見市鶴瀬西3-3-1`,
              `電話番号：049-251-4144`,
              `FAX：049-251-4112`,
              `平成11年2月開業`,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OfficeInfoCard
            cardTitle="所長の紹介"
            contentsList={[
              `鈴木弘基（税理士・行政書士）`,
              `関東信越税理士会川越支部`,
              `登録番号第87701号`,
              `埼玉県行政書士会東入間支部`,
              `登録番号第02134231号`,
              `経営革新等支援機関認定`,
              `富士見市固定資産評価審査委員会 委員長`,
              `富士見市商工会 監事`,
              `つるせ台商店街 会長`,
            ]}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default OfficeInfoCardsBox;
