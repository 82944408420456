import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import OfficeInfoCardsBox from '@/components/officeInfo/section/OfficeInfoCardsBox';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const OfficeInfo: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="事務所情報"
      descriptionData="鈴木弘基税理士事務所の基本情報や所長の登録情報等はこちらに記載しております。"
      OgImageData="OfficeInfo"
    />
    <FirstView pageTitle="事務所情報" fileName="OfficeInfo" />
    <OfficeInfoCardsBox />
    <BottomMenu />
  </Layout>
);

export default OfficeInfo;
